import styled from 'styled-components';
import { hexToRgba } from 'styles/mixins';

export const OnboaringInfoContainer = styled.div`
  min-width: 800px;
  padding: 25px 0;
`;

export const OnboaringInfoHeadingContainer = styled.div`
  padding: 16px 0 0;
  border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.75)};
`;

export const OnboaringInfoHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black0};
`;

export const InfoContainer = styled.div`
  padding: 16px 0;
`;

export const InfoInstruction = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black0};
`;

export const InfoList = styled.ul`
  padding: 8px 32px;
  list-style: disc;
`;

export const InfoListItem = styled.li`
  line-height: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

import { PropsWithRef, forwardRef, useState } from 'react';
import { Input, FormInputContainer, FormLabel, InputError, CurrencyPrefix } from './input.styles';

export interface InputProps extends PropsWithRef<JSX.IntrinsicElements['input']> {
  label?: string;
  className?: string;
  id: string;
  column?: 2 | 3;
  error?: string | null;
  format?: 'currency';
}

export const FormInput = forwardRef<HTMLInputElement, InputProps>(function InputField(
  { label, className, id, column, error, format, onChange, ...rest },
  ref
) {
  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (format === 'currency') {
      const numericValue = newValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const parts = numericValue.split('.');
      if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
      }
      const formattedValue = parts.join('.');

      setValue(formattedValue);

      if (onChange) {
        e.target.value = formattedValue;
        onChange(e);
      }
    } else {
      setValue(newValue);
      if (onChange) onChange(e);
    }
  };

  return (
    <FormInputContainer className={className ? `${className}` : ''} column={column}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {format === 'currency' && <CurrencyPrefix>$</CurrencyPrefix>}
      <Input id={id} ref={ref} value={value} onChange={handleChange} format={format} {...rest} />
      {error && <InputError>{error}</InputError>}
    </FormInputContainer>
  );
});

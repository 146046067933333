import { StyledTable, Thead, Tr, Th, Td, Tbody } from '../Table.styles';
import { Payroll } from 'lib/fetchCompanyPayrolls';
import { formatName } from 'lib/utils/formatName';
import { formatDate } from 'lib/utils/formatDate';
import InfoPlaceholder from 'components/Placeholder/Info';

export type TableProps = {
  payrolls: Payroll[];
};

export const DraftsTable = ({ payrolls }: TableProps) => {
  if (payrolls.length === 0) {
    return <InfoPlaceholder title="No Drafts Created" image="tucker" />;
  }

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Payday</Th>
          <Th>Pay Period</Th>
          <Th>Type</Th>
          <Th>Submittal Deadline</Th>
        </Tr>
      </Thead>
      <Tbody>
        {payrolls.map((payroll) => (
          <Tr key={payroll.providerId}>
            <Td>{formatDate(payroll.payday)}</Td>
            <Td>{`${formatDate(payroll.periodStart)} - ${formatDate(payroll.periodEnd)}`}</Td>
            <Td>{formatName(payroll.type)}</Td>
            <Td>{formatDate(payroll.approvalDeadline)}</Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};

import { useState, useEffect } from 'react';
import { TabMenuContainer, TabMenuItem, StyledTabMenu } from './tabMenu.styles';

type TabMenuProps = {
  menuItems: { [key: string]: JSX.Element };
  defaultActiveItem: string;
  onTabChange?: (tab: string) => void;
};

export const TabMenu = ({ menuItems, defaultActiveItem, onTabChange }: TabMenuProps) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  const handleClick = (item: string) => {
    setActiveItem(item);
    if (onTabChange) {
      onTabChange(item);
    }
  };

  useEffect(() => {
    setActiveItem(defaultActiveItem);
  }, [defaultActiveItem]);

  return (
    <TabMenuContainer>
      <StyledTabMenu>
        {Object.keys(menuItems).map((item, index) => (
          <TabMenuItem key={index} onClick={() => handleClick(item)} active={activeItem === item}>
            {item}
          </TabMenuItem>
        ))}
      </StyledTabMenu>
      {menuItems[activeItem]}
    </TabMenuContainer>
  );
};

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { childSupportDeductionSchema } from '../validator';
import { ChildSupportDeductionFormData } from '../types';
import moment from 'moment';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FormGroup, FormInput } from 'components/form';
import { DateInput } from 'components/form/DateInput/dateInput';
import { DeductionFormContainer, Form, ButtonContainer } from '../PostTaxDeductionForm.styles';
import { createPostTaxDeduction, PostTaxDeductionInput } from 'lib/createPostTaxDeduction';
import { PostTaxDeductionTypes } from 'lib/fetchEmployeePostTaxDeductions';
import { Employee } from 'features/user/types';
import { toast } from 'react-toastify';

type ChildSupportProps = {
  employee: Employee;
  onFormSubmit: () => void;
};

export const ChildSupportDeductionForm = ({ employee, onFormSubmit }: ChildSupportProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChildSupportDeductionFormData>({
    resolver: yupResolver(childSupportDeductionSchema),
  });

  const onSubmit: SubmitHandler<ChildSupportDeductionFormData> = async (
    data: ChildSupportDeductionFormData
  ) => {
    const postTaxDeductionData: PostTaxDeductionInput = {
      employeeId: employee.id,
      type: 'child_support' as PostTaxDeductionTypes,
      description: data.description,
      effectiveStart: moment(data.effectiveStart).format('YYYY-MM-DD'),
      effectiveEnd: data.effectiveEnd ? moment(data.effectiveEnd).format('YYYY-MM-DD') : undefined,
      configurationObject: {
        childSupport: {
          agency: data.agency,
          amount: data.amount,
          externalId: data.externalId,
          issueDate: moment(data.issueDate).format('YYYY-MM-DD'),
          maxPercent: parseFloat(data.maxPercent),
        },
      },
    };

    try {
      const result = await createPostTaxDeduction(postTaxDeductionData);
      if (result) {
        toast.success('Employee deduction has been successfully added.');
        onFormSubmit();
      }
    } catch (error) {
      toast.error('Failed to create deduction.');
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('description') || !watch('effectiveStart') || !watch('agency') || !watch('externalId');

  return (
    <DeductionFormContainer>
      <Form>
        <FormGroup label="General Information">
          <FormInput
            id="description"
            placeholder="Description"
            type="text"
            required
            {...register('description')}
          />
          <FormInput
            id="agency"
            placeholder="Agency"
            type="text"
            required
            {...register('agency')}
          />
          <FormInput
            id="externalId"
            placeholder="External ID"
            type="text"
            required
            {...register('externalId')}
          />
          <DateInput control={control} name="issueDate" placeholder="Issue Date" />
        </FormGroup>
        <FormGroup label="Effective Dates">
          <DateInput control={control} name="effectiveStart" placeholder="Start Date" />
          <DateInput control={control} name="effectiveEnd" placeholder="End Date" />
        </FormGroup>
        <FormGroup label="Amount">
          <FormInput
            id="amount"
            placeholder="Amount Per Pay Period (Required)"
            type="text"
            required
            format="currency"
            {...register('amount')}
          />
          <FormInput
            id="maxPercent"
            placeholder="Max Percent (Required)"
            type="text"
            required
            {...register('maxPercent')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          title="Add Deduction"
          size="medium"
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </DeductionFormContainer>
  );
};

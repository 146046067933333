import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { DocumentsTable } from 'components/Table/DocumentsTable/DocumentsTable';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'features/user/slice';
import { Roles } from 'features/user/types';
import { TabMenu } from 'components/TabMenu/tabMenu';
import { CompanyAuthorizationDocuments } from 'components/TabMenu/Tabs/CompanyDocumentMenu/CompanyAuthorizationDocuments';
import { CompanyTaxDocuments } from 'components/TabMenu/Tabs/CompanyDocumentMenu/CompanyTaxDocuments';
import { CompanyProvidedDocuments } from 'components/TabMenu/Tabs/CompanyDocumentMenu/CompanyProvidedDocuments';

const MENU_ITEMS = {
  'Company Authorization Documents': <CompanyAuthorizationDocuments />,
  'Company Tax Documents': <CompanyTaxDocuments />,
  'Company Provided Documents': <CompanyProvidedDocuments />,
};

export const Documents = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  let employee = undefined;

  if (currentUser && currentUser.employee) {
    employee = currentUser.employee;
  }

  // Employer & Payroll Admin Documents UI
  if (currentUser && currentUser.role.name !== Roles.Employee) {
    return (
      <PageContainer>
        <SectionHeader title="Documents" />
        <TabMenu menuItems={MENU_ITEMS} defaultActiveItem="Company Authorization Documents" />
      </PageContainer>
    );
  }

  // Employee Documents UI
  return (
    <PageContainer>
      <SectionHeader title="Documents" message="Download and view previously signed documents." />
      <ContentContainer>
        <DocumentsTable employee={employee} />
      </ContentContainer>
    </PageContainer>
  );
};

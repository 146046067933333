import { NotificationContainer, Container, Message, Heading } from '../notification.styles';

export const WelcomeNotification = () => {
  return (
    <NotificationContainer>
      <Container>
        <Heading>Welcome</Heading>
        <Message>
          Your application is under review. In the meantime, you can familiarize yourself with
          Procare Payroll and start adding your employees to be ready for your first payroll run.{' '}
        </Message>
      </Container>
    </NotificationContainer>
  );
};

import { createRoot } from 'react-dom/client';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import { FontStyles } from 'styles/fontStyles';
import { GlobalStyles } from 'styles/globalStyles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles/dateInput.scss';

// Import Pendo initialization
import { Pendo } from 'components/Pendo/Pendo';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <FontStyles />
    <GlobalStyles />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />

        <Pendo />

        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={true}
          closeOnClick
          pauseOnHover
          className="toastMessage"
        />
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

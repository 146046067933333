/* eslint-disable no-console */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'features/user/slice';
import { selectCurrentCompany } from 'features/company/slice';
import { initializePendo } from '@src/pendo';

export const Pendo = () => {
  const user = useSelector(selectCurrentUser);
  const company = useSelector(selectCurrentCompany);

  useEffect(() => {
    if (user && company) {
      initializePendo(user, company);
    }
  }, [user, company]);

  return null;
};

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { DateSelector } from 'components/DateSelector/dateSelector';
import { Moment } from 'moment';
import { DateLabel } from './dateInput.styles';

interface DateInputProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  placeholder?: string;
  label?: string;
  defaultValue?: string;
  openDirection?: 'up' | 'down';
  dateRestriction?: 'past' | 'future';
  blockWeekends?: boolean;
  allowSemimonthlyOnly?: boolean;
  onChange?: (date: string | null) => void;
}

export const DateInput = <TFieldValues extends FieldValues>({
  control,
  name,
  placeholder,
  label,
  defaultValue,
  openDirection = 'down',
  dateRestriction,
  blockWeekends = false,
  allowSemimonthlyOnly = false,
  onChange,
}: DateInputProps<TFieldValues>) => {
  return (
    <>
      {label && <DateLabel htmlFor={name}>{label}</DateLabel>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue as any}
        render={({ field }) => (
          <DateSelector
            placeholder={placeholder}
            openDirection={openDirection}
            defaultValue={defaultValue}
            dateRestriction={dateRestriction}
            blockWeekends={blockWeekends}
            allowSemimonthlyOnly={allowSemimonthlyOnly}
            onDateChange={(date: Moment | null) => {
              const formattedDate = date ? date.format('YYYY-MM-DD') : null;
              field.onChange(formattedDate);
              if (onChange) {
                onChange(formattedDate);
              }
            }}
          />
        )}
      />
    </>
  );
};

// Add the necessary imports
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { miscellaneousDeductionSchema } from '../validator';
import { MiscellaneousDeductionFormData } from '../types';
import moment from 'moment';
import { ActionButton } from 'components/ActionButton/actionButton';
import { FormGroup, FormInput } from 'components/form';
import { DateInput } from 'components/form/DateInput/dateInput';
import { RadioInput } from 'components/form/RadioInput/RadioInput';
import { DeductionFormContainer, Form, ButtonContainer } from '../PostTaxDeductionForm.styles';
import { UpdatePostTaxDeductionInput, updatePostTaxDeduction } from 'lib/updatePostTaxDeduction';
import {
  DeductionNode,
  ConfigurationObject,
  MiscellaneousDeduction,
} from 'lib/fetchEmployeePostTaxDeductions';
import { toast } from 'react-toastify';
import { useState } from 'react';

type UpdateMiscDeductionProps = {
  deduction: DeductionNode | null;
  onFormSubmit: () => void;
};

const isMiscellaneousDeduction = (
  obj: ConfigurationObject | undefined
): obj is MiscellaneousDeduction => (obj ? 'amount' in obj : false);

export const UpdateMiscDeductionForm = ({ deduction, onFormSubmit }: UpdateMiscDeductionProps) => {
  const defaultValues = {
    description: deduction?.description,
    amount: isMiscellaneousDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.amount || null
      : null,
    annualLimit: isMiscellaneousDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.annualLimit || null
      : null,
    percent: isMiscellaneousDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.percent?.toString() || null
      : null,
    totalAmount: isMiscellaneousDeduction(deduction?.configurationObject)
      ? deduction?.configurationObject?.totalAmount || null
      : null,
    effectiveStart: deduction?.effectiveStart,
    effectiveEnd: deduction?.effectiveEnd || null,
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<MiscellaneousDeductionFormData>({
    resolver: yupResolver(miscellaneousDeductionSchema),
    defaultValues,
  });

  const [amountOrPercent, setAmountOrPercent] = useState<'amount' | 'percent'>(
    defaultValues.amount ? 'amount' : 'percent'
  );

  const [deductionType, setDeductionType] = useState<'recurring' | 'oneTime'>(
    defaultValues.effectiveEnd ? 'recurring' : 'oneTime'
  );

  const amountOrPercentOptions = [
    { value: 'amount', label: 'Fixed Amount' },
    { value: 'percent', label: 'Percentage of Pay' },
  ];

  const deductionTypeOptions = [
    { value: 'recurring', label: 'Recurring Deduction' },
    { value: 'oneTime', label: 'One-time Deduction' },
  ];

  const onSubmit: SubmitHandler<MiscellaneousDeductionFormData> = async (
    data: MiscellaneousDeductionFormData
  ) => {
    const miscellaneousData: { [key: string]: any } = {};

    if (data.amount && data.amount.trim() !== '') miscellaneousData.amount = data.amount;

    if (data.annualLimit && data.annualLimit.trim() !== '') {
      miscellaneousData.annualLimit = data.annualLimit;
    } else {
      miscellaneousData.annualLimit = null;
    }

    if (data.percent && data.percent.trim() !== '')
      miscellaneousData.percent = parseFloat(data.percent);

    if (data.totalAmount && data.totalAmount.trim() !== '') {
      miscellaneousData.totalAmount = data.totalAmount;
    } else {
      miscellaneousData.totalAmount = null;
    }

    const postTaxDeductionData: UpdatePostTaxDeductionInput = {
      providerId: deduction?.providerId || '',
      description: data.description,
      effectiveStart: moment(data.effectiveStart).format('YYYY-MM-DD'),
      effectiveEnd:
        deductionType === 'oneTime'
          ? null
          : data.effectiveEnd
          ? moment(data.effectiveEnd).format('YYYY-MM-DD')
          : undefined,
      configurationObject: {
        miscellaneous: miscellaneousData,
      },
    };

    try {
      const result = await updatePostTaxDeduction(postTaxDeductionData);
      if (result) {
        toast.success('Employee deduction has been successfully updated.');
        onFormSubmit();
      }
    } catch (error) {
      toast.error('Failed to update deduction.');
    }
  };

  const isButtonDisabled = (): boolean => !watch('description') || !watch('effectiveStart');

  const onChangeAmountOrPercent = (value: 'amount' | 'percent') => {
    if (value === 'percent') {
      setValue('amount', '');
    } else if (value === 'amount') {
      setValue('percent', '');
    }
    setAmountOrPercent(value);
  };

  return (
    <DeductionFormContainer>
      <Form>
        <FormGroup label="General Information">
          <FormInput
            id="description"
            placeholder="Description"
            type="text"
            required
            {...register('description')}
          />
        </FormGroup>
        <FormGroup label="Frequency">
          <RadioInput
            name="deductionType"
            options={deductionTypeOptions}
            selectedValue={deductionType}
            onChange={(value) => setDeductionType(value as 'recurring' | 'oneTime')}
          />
          <DateInput
            control={control}
            name="effectiveStart"
            placeholder="Start Date"
            defaultValue={moment(defaultValues.effectiveStart).format('YYYY-MM-DD')}
          />
          {deductionType === 'recurring' && (
            <DateInput
              control={control}
              name="effectiveEnd"
              placeholder="End Date"
              defaultValue={
                defaultValues.effectiveEnd
                  ? moment(defaultValues.effectiveEnd).format('YYYY-MM-DD')
                  : ''
              }
            />
          )}
        </FormGroup>

        <FormGroup label="Amount">
          <RadioInput
            name="amountOrPercent"
            options={amountOrPercentOptions}
            selectedValue={amountOrPercent}
            onChange={(value) => onChangeAmountOrPercent(value as 'amount' | 'percent')}
          />
          {amountOrPercent === 'amount' && (
            <FormInput
              id="amount"
              placeholder="Amount Per Pay Period"
              type="text"
              format="currency"
              {...register('amount')}
            />
          )}
          {amountOrPercent === 'percent' && (
            <FormInput id="percent" placeholder="Percent" type="text" {...register('percent')} />
          )}
          <FormInput
            id="annualLimit"
            placeholder="Annual Limit"
            type="text"
            format="currency"
            {...register('annualLimit')}
          />
          <FormInput
            id="totalAmount"
            placeholder="Total Amount"
            type="text"
            format="currency"
            {...register('totalAmount')}
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          title="Save Deduction"
          size="medium"
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </DeductionFormContainer>
  );
};

import { useAppDispatch } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { setCompanyOnboarded } from 'features/company/slice';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { Container, Heading, Message, ButtonContainer } from './OnboardingComplete.styles';
import { ActionButton } from 'components/ActionButton/actionButton';

export const OnboardingComplete = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnboarding = async () => {
    await dispatch(setCompanyOnboarded(true));
    navigate('/dashboard');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <ContentContainer onboarding={true} contentAlign="center">
      <Container>
        <Heading>Thank you!</Heading>
        <Message>
          We are processing your application for Procare Payroll access. Please expect to receive a
          confirmation email within five business days.
        </Message>
        <Message>
          In the meantime, you can start setting up your Procare Payroll account by adding your
          employees. This will ensure a smooth transition once you&apos;re approved.
        </Message>
        <ButtonContainer>
          <ActionButton
            onClick={handleOnboarding}
            size="large"
            title="Continue to Procare Payroll"
            hidden={false}
          />
        </ButtonContainer>
      </Container>
    </ContentContainer>
  );
};

import styled from 'styled-components';
import { TooltipProps } from './tooltip';

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const Tip = styled.div<{ direction?: TooltipProps['direction'] }>`
  position: absolute;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: ${(props) => props.theme.tooltip.textColor};
  background: ${(props) => props.theme.tooltip.backgroundColor};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamily.default};
  line-height: 1.5;
  z-index: ${(props) => props.theme.zIndex.tooltip};
  min-width: 250px;
  white-space: normal;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${(props) => props.theme.tooltip.arrowSize};
    margin-left: calc(${(props) => props.theme.tooltip.arrowSize} * -1);
  }

  ${(props) =>
    props.direction === 'top' &&
    `
    top: calc(${props.theme.tooltip.margin} * -1);
    &::before {
      top: 100%;
      border-top-color: ${props.theme.tooltip.backgroundColor};
    }
  `}

  ${(props) =>
    props.direction === 'right' &&
    `
    left: calc(100% + ${props.theme.tooltip.margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &::before {
      left: calc(${props.theme.tooltip.arrowSize} * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: ${props.theme.tooltip.backgroundColor};
    }
  `}

  ${(props) =>
    props.direction === 'bottom' &&
    `
    top: 100px;
    transform: translateX(-10%) translateY(-100%);
    &::before {
      bottom: 100%;
      left: 30px;
      border-bottom-color: ${props.theme.tooltip.backgroundColor};
    }
  `}

  ${(props) =>
    props.direction === 'left' &&
    `
    left: auto;
    right: calc(100% + ${props.theme.tooltip.margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &::before {
      left: auto;
      right: calc(${props.theme.tooltip.arrowSize} * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: ${props.theme.tooltip.backgroundColor};
    }
  `}
`;

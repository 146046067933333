import { useEffect, useState, useCallback } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { DraftsTable } from './DraftsTable';
import { fetchCompanyPayrolls, Payroll, PageInfo, PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';
import { FormSelect } from 'components/form';
import { CurrentUserContext } from 'features/user/types';
import { ControlBar } from '../Table.styles';
import { ConfirmDeleteModal } from './ConfirmDeleteModal/ConfirmDeleteModal';
import { deletePayroll } from 'lib/deletePayroll';
import { toast } from 'react-toastify';
import { formatName } from 'lib/utils/formatName';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type PayrollsTableProps = {
  refresh?: boolean;
  status?: PayrollStatus;
  pageSize?: number;
  disablePagination?: boolean;
};

export const PayrollsTable = ({
  refresh = false,
  status,
  pageSize = DEFAULT_PAGE_SIZE,
  disablePagination = false,
}: PayrollsTableProps) => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [payrolls, setPayrolls] = useState<Payroll[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [payrollToDelete, setPayrollToDelete] = useState<string | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<PayrollStatus | undefined>(status);

  const fetchPayrolls = useCallback(() => {
    setLoading(true);
    fetchCompanyPayrolls(endCursor, { status: selectedStatus }, pageSize).then(
      ({ payrolls: newPayrolls, pageInfo: newPageInfo }) => {
        setPayrolls(newPayrolls);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [endCursor, selectedStatus, pageSize]);

  useEffect(() => {
    fetchPayrolls();
  }, [currentUser, endCursor, refresh, selectedStatus, pageSize, fetchPayrolls]);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  const handleDelete = (providerId: string) => {
    setPayrollToDelete(providerId);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (payrollToDelete) {
      try {
        await deletePayroll({ providerId: payrollToDelete });
        toast.success('Payroll has been deleted.');
        setDeleteModalOpen(false);
        setPayrollToDelete(null);
        fetchPayrolls();
      } catch (error) {
        toast.error('Unable to delete payroll');
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setPayrollToDelete(null);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? undefined : (e.target.value as PayrollStatus);
    setSelectedStatus(value);
  };

  const renderTable = () => {
    if (selectedStatus === PayrollStatus.Draft) {
      return <DraftsTable payrolls={payrolls} />;
    } else {
      return <Table payrolls={payrolls} onDelete={handleDelete} status={selectedStatus} />;
    }
  };

  const statusOptions = Object.values(PayrollStatus)
    .filter((payrollStatus) => payrollStatus !== PayrollStatus.Draft)
    .map((payrollStatus) => ({
      value: payrollStatus,
      label: formatName(payrollStatus),
    }));

  return loading ? (
    <Spinner />
  ) : (
    <>
      <ControlBar>
        {status && status !== PayrollStatus.Draft && (
          <FormSelect
            id="payrollStatus"
            options={[{ value: '', label: 'All Statuses' }, ...statusOptions]}
            value={selectedStatus ?? ''}
            onChange={handleStatusChange}
          />
        )}
        {!disablePagination && pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
          <Pagination
            onPrev={onPrev}
            onNext={onNext}
            disablePrev={!pageInfo.hasPreviousPage}
            disableNext={!pageInfo.hasNextPage}
          />
        )}
      </ControlBar>
      {renderTable()}
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { OffCyclePayrollFormData } from './types';
import {
  OffCyclePayrollModalContainer,
  CloseButton,
  TitleContainer,
  ModalTitle,
  Instruction,
  EmployeeContainer,
  EmployeeItem,
  HeaderContainer,
  NoStaffMessage,
  InitialCircle,
  EmployeeLabel,
  SearchContainer,
  SearchInput,
  ButtonContainer,
  Period,
  PeriodContainer,
  Step,
} from './OffCyclePayrollModal.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { fetchEmployeeList, EmployeeBasic as Employee } from 'lib/fetchEmployeeList';
import { ActionButton } from 'components/ActionButton/actionButton';
import moment from 'moment';
import { PayrollTypes } from 'lib/createPayroll';
import { Payday } from 'lib/fetchCompanyNextPayday';

type OffCyclePayrollModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  formData: OffCyclePayrollFormData | null;
};

export const OffCyclePayrollModal = ({
  isOpen,
  onRequestClose,
  formData,
}: OffCyclePayrollModalProps) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<{ [key: string]: boolean }>({});
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const isLoading = useRef(false);

  const navigate = useNavigate();

  const fetchEmployees = useCallback(
    async (cursor?: string, isReset = false) => {
      if (isLoading.current || (!hasNextPage && !isReset)) return;
      isLoading.current = true;

      try {
        const customPageSize = 12;
        const response = await fetchEmployeeList(customPageSize, cursor, searchName, true);

        if (response.pageInfo?.hasNextPage === false && !response.employees.length) {
          setHasNextPage(false);
          return;
        }

        setEmployees((prevEmployees) =>
          isReset ? response.employees : [...prevEmployees, ...response.employees]
        );

        setEndCursor(response.pageInfo?.endCursor || undefined);
        setHasNextPage(response.pageInfo?.hasNextPage || false);
      } finally {
        isLoading.current = false;
      }
    },
    [searchName, hasNextPage]
  );

  useEffect(() => {
    if (isOpen) {
      setEmployees([]);
      setEndCursor(undefined);
      setHasNextPage(true);
      fetchEmployees(undefined, true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isSearchTriggered) return;

    if (searchName === '') {
      fetchEmployees(undefined, true);
      setIsSearchTriggered(false);
      return;
    }

    const handler = setTimeout(() => {
      if (isOpen) {
        fetchEmployees(undefined, true);
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchName, isOpen, isSearchTriggered, fetchEmployees]);

  const handleScroll = useCallback(() => {
    if (isLoading.current || !hasNextPage) return;
    const container = document.getElementById('employee-container');
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        fetchEmployees(endCursor);
      }
    }
  }, [endCursor, hasNextPage, fetchEmployees]);

  useEffect(() => {
    const container = document.getElementById('employee-container');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
    setIsSearchTriggered(true);
  };

  const toggleCheckbox = (id: string) => {
    setSelectedEmployees((prev) => {
      const newSelected = { ...prev, [id]: !prev[id] };
      setIsAllSelected(
        Object.keys(newSelected).length === employees.length &&
          Object.values(newSelected).every((isSelected) => isSelected)
      );
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    const allSelected = !isAllSelected;
    const newSelectedEmployees = employees.reduce((acc, employee) => {
      acc[employee.id] = allSelected;
      return acc;
    }, {} as { [key: string]: boolean });
    setSelectedEmployees(newSelectedEmployees);
    setIsAllSelected(allSelected);
  };

  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const noStaffSelected =
    Object.keys(selectedEmployees).length === 0 ||
    Object.values(selectedEmployees).every((isSelected) => !isSelected);

  const formatPayPeriod = (startDate: string, endDate: string) => {
    return `Pay Period: ${moment(startDate).format('MMMM D, YYYY')} - ${moment(endDate).format(
      'MMMM D, YYYY'
    )}`;
  };

  const handleContinue = () => {
    if (formData) {
      const selectedEmployeeIds = Object.keys(selectedEmployees).filter(
        (key) => selectedEmployees[key]
      );

      const payday: Payday = {
        approvalDeadline: '',
        impactedByWeekendOrHoliday: false,
        payday: formData.payday,
        periodEnd: formData.endDate,
        periodStart: formData.startDate,
      };

      navigate('/payroll/run-payroll/hours', {
        state: {
          payday,
          type: PayrollTypes.OFF_CYCLE,
          employeeIds: selectedEmployeeIds,
        },
      });
    }
  };

  return (
    <OffCyclePayrollModalContainer>
      <TitleContainer>
        <ModalTitle>
          Select Employees <Step>Step 2 / 2</Step>
        </ModalTitle>
        <Instruction>
          Select which employees you would like to run an off-cycle payroll for.
        </Instruction>
      </TitleContainer>
      {formData && (
        <PeriodContainer>
          <Period>{formatPayPeriod(formData.startDate, formData.endDate)}</Period>
        </PeriodContainer>
      )}
      <SearchContainer>
        <SearchInput
          type="text"
          value={searchName}
          onChange={handleSearchChange}
          placeholder="Search by name"
        />
      </SearchContainer>
      <HeaderContainer>
        <Checkbox
          id="selectAll"
          isChecked={isAllSelected}
          toggleCheckbox={toggleSelectAll}
          label="Select All"
        />
        {noStaffSelected && <NoStaffMessage>No Staff Selected</NoStaffMessage>}
      </HeaderContainer>
      <EmployeeContainer
        id="employee-container"
        style={employees.length > 6 ? { height: '300px', overflowY: 'auto' } : {}}
      >
        {employees.map((employee, index) => {
          const isInLastRow = index >= Math.floor(employees.length / 3) * 3;
          return (
            <EmployeeItem key={employee.id} isInLastRow={isInLastRow}>
              <Checkbox
                id={employee.id}
                isChecked={selectedEmployees[employee.id] || false}
                toggleCheckbox={() => toggleCheckbox(employee.id)}
                label={
                  <EmployeeLabel>
                    <InitialCircle>{getInitials(employee.name)}</InitialCircle>
                    {employee.name}
                  </EmployeeLabel>
                }
              />
            </EmployeeItem>
          );
        })}
      </EmployeeContainer>
      <ButtonContainer>
        <ActionButton
          size="large"
          title="Continue"
          hidden={false}
          disabled={noStaffSelected}
          onClick={handleContinue}
        />
      </ButtonContainer>
      <CloseButton icon={<CloseIcon />} onClick={onRequestClose} />
    </OffCyclePayrollModalContainer>
  );
};

import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';
import { EarningType } from './fetchProviderPayroll';

export type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

export type PayrollEarning = {
  amount: number;
  description: string;
  earningCode: string;
  earningRate: number;
  hours: number;
  type: EarningType;
  workplaceIdentifier: string;
};

export type PayrollTax = {
  amount: number;
  description: string;
  payer: string;
  providerId: string;
  remittable: boolean;
};

export type PayrollReimbursement = {
  amount: number;
  code: string;
  description: string;
};

export type EmployeeCompensation = {
  amount: number;
  type: string;
};

export type Employee = {
  active: boolean;
  id: string;
  name: string;
  onboarded: boolean;
  terminationDate: string | null;
  totalWorkHours: number;
  compensation: EmployeeCompensation;
  providerIdentifier: string;
};

export type PayrollItem = {
  id: string;
  providerIdentifier: string;
  providerAttributes: {
    netPay: number;
    paymentMethod: string;
    providerId: string;
    status: string;
    earnings: PayrollEarning[];
    taxes: PayrollTax[];
    reimbursements: PayrollReimbursement[];
    payrollProviderId: string;
  };
  employee: Employee;
};

export type PayrollItemsListResponse = {
  data: {
    payrollItemsList: {
      edges: {
        cursor: string;
        node: PayrollItem;
      }[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
};

export type PayrollItemsListResult = {
  items: PayrollItem[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const fetchPayrollItemsList = async (
  providerId: string,
  first = 20,
  after?: string,
  before?: string
): Promise<PayrollItemsListResult | void> => {
  const query = `
    query PayrollItemsList($payrollInput: PayrollInput!, $first: Int!, $after: String, $before: String) {
      payrollItemsList(payrollInput: $payrollInput, first: $first, after: $after, before: $before) {
        edges {
          cursor
          node {
            id
            providerIdentifier
            providerAttributes {
              netPay
              paymentMethod
              providerId
              status
              earnings {
                amount
                description
                earningCode
                earningRate
                hours
                type
                workplaceIdentifier
              }
              taxes {
                amount
                description
                payer
                providerId
                remittable
              }
              reimbursements {
                amount
                code
                description
              }
              payrollProviderId
            }
            employee {
              active
              id
              name
              onboarded
              terminationDate
              totalWorkHours
              compensation {
                amount
                type
              }
              providerIdentifier
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `;

  const variables = {
    payrollInput: { providerId },
    first,
    after,
    before,
  };

  return procareApi
    .post<unknown, AxiosResponse<PayrollItemsListResponse>>('graphql', {
      query,
      variables,
    })
    .then((response: AxiosResponse<PayrollItemsListResponse>) => {
      const data = response.data.data.payrollItemsList;
      return {
        items: data.edges.map((edge) => edge.node),
        pageInfo: data.pageInfo,
      };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      throw error;
    });
};

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payScheduleSchema } from './validator';
import { PayScheduleFormData, PayFrequencyType } from './types';
import { Form, PayScheduleFormContainer, ButtonContainer, Instruction } from './paySchedule.styles';
import { FormSelect, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { createPaySchedule } from 'lib/createPaySchedule';
import { setCompanyStartDate } from 'lib/setCompanyStartDate';
import { toast } from 'react-toastify';

const defaultFormValues = {
  payFrequency: Object.values(PayFrequencyType)[0],
};

type PayScheduleFormProps = {
  onboarding?: boolean;
};

export const PayScheduleForm = ({ onboarding }: PayScheduleFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PayScheduleFormData>({
    resolver: yupResolver(payScheduleSchema),
    defaultValues: defaultFormValues,
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<PayScheduleFormData> = async (data: PayScheduleFormData) => {
    if (Object.keys(errors).length === 0) {
      if (onboarding) {
        // Set the Company Start Date first
        const startDateFormatted = moment(data.startDate).format('YYYY-MM-DD');
        try {
          await setCompanyStartDate(startDateFormatted);
        } catch (error) {
          toast.error('Failed to set the company start date.');
          return; // Exit if there's an error setting the start date
        }
      }

      // Proceed to create the Pay Schedule
      const payScheduleData: PayScheduleFormData = {
        payFrequency: data.payFrequency,
        firstPayday: moment(data.firstPayday).format('YYYY-MM-DD'),
        firstPeriodEnd: moment(data.firstPeriodEnd).format('YYYY-MM-DD'),
      };

      const response = await createPaySchedule(payScheduleData);

      if (response) {
        toast.success('Pay Schedule has been successfully added.');
        navigate('/company-onboarding');
      } else {
        toast.error('Failed to create the pay schedule.');
      }
    }
  };

  const isButtonDisabled = (): boolean => {
    const startDateValue = watch('startDate');
    const firstPaydayValue = watch('firstPayday');

    const isFieldsEmpty =
      !watch('payFrequency') || !watch('firstPayday') || !watch('firstPeriodEnd');

    if (isFieldsEmpty) {
      return true;
    }

    if (
      onboarding &&
      (!startDateValue || !firstPaydayValue || moment(startDateValue).isAfter(firstPaydayValue))
    ) {
      return true;
    }

    return false;
  };

  const selectedPayFrequency = watch('payFrequency');

  const payFrequencyOptions = Object.values(PayFrequencyType).map((type) => ({
    value: type,
    label: type,
  }));

  const defaultPayFrequencyValue = payFrequencyOptions.length ? payFrequencyOptions[0].value : '';

  return (
    <PayScheduleFormContainer>
      <Form>
        {onboarding && (
          <FormGroup label="Start Date">
            <Instruction>
              This date should be a future date and should be the same or before your first pay day.
              This date is the first date Payroll will officially be run.
            </Instruction>
            <DateInput
              control={control}
              name="startDate"
              dateRestriction="future"
              blockWeekends={true}
            />
          </FormGroup>
        )}
        <FormGroup label="Pay Frequency">
          <FormSelect
            id="payFrequency"
            required
            options={payFrequencyOptions}
            defaultValue={defaultPayFrequencyValue}
            {...register('payFrequency')}
          />
        </FormGroup>
        <FormGroup label="Period Details">
          <DateInput control={control} name="firstPeriodEnd" label="First Period End Date" />
          {selectedPayFrequency === PayFrequencyType.SEMIMONTHLY && (
            <Instruction>
              First Pay Day must be either the 15th or the last day of the month when using a Semi
              Monthly pay frequency.
            </Instruction>
          )}
          <DateInput
            control={control}
            name="firstPayday"
            label="First Payday"
            dateRestriction="future"
            allowSemimonthlyOnly={selectedPayFrequency === PayFrequencyType.SEMIMONTHLY}
            blockWeekends={
              selectedPayFrequency === PayFrequencyType.WEEKLY ||
              selectedPayFrequency === PayFrequencyType.BIWEEKLY
            }
          />
        </FormGroup>
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="large"
          title="Continue"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </PayScheduleFormContainer>
  );
};

import styled, { keyframes } from 'styled-components';
import { Td } from '../Table.styles';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactComponent as SaveIcon } from '@src/assets/icons/save.svg';
import { ReactComponent as SavingIcon } from '@src/assets/icons/saving.svg';

export const ModalHeader = styled.div`
  border-bottom: 2px solid #e1dfdd;
`;

export const ModalLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

export const TimecardHeading = styled.h2`
  color: ${(props) => props.theme.colors.black};
  padding: 0 16px;
  font-size: 24px;
`;

export const Heading = styled.h2`
  color: ${(props) => props.theme.colors.black};
  padding: 0 16px;
  font-size: 20px;
`;

export const ReimbursementsInstructions = styled.p`
  margin: 24px 0;
  color: ${(props) => props.theme.colors.black};
`;

export const DateRange = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  margin: 32px 8px 8px;
`;

export const TimecardDateRange = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 18px;
  margin: 16px;
`;

export const TableContainer = styled.div`
  padding: 0 16px 12px;
`;

export const TotalItem = styled(Td)`
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  background-color: #edebe9;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
`;

export const RemoveButton = styled(IconButton)`
  padding-top: 20px;
  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const AddButton = styled(IconButton)`
  padding-top: 20px;
  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const HorizontalRule = styled.hr`
  width: 100px;
`;

export const AddContainer = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px 0;
`;

export const ReimbursementContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black};
`;

export const Input = styled.input<{ isCurrency?: boolean }>`
  flex: 1;
  margin: 0;
  width: 100%;
  min-width: 140px;
  padding: ${(props) => (props.isCurrency ? '8px 10px 8px 25px' : '8px 10px')};
  height: 40px;

  border: 1px solid ${(props) => props.theme.colors.gray0};
  border-radius: 6px;
  outline: none;

  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamily.default};

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.blue2} !important;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const DollarSign = styled.span`
  position: absolute;
  left: 10px;
  font-size: 14px;
  pointer-events: none;
  z-index: 1;
`;

export const CenteredTd = styled(Td)`
  text-align: center;
`;

export const CompensationTd = styled(Td)`
  font-weight: bold;
  font-size: 18px;
`;

export const TableBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveStatusContainer = styled.div<{ isPaginated?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-right: ${({ isPaginated }) => (isPaginated ? '60px' : '8px')};
`;

export const SavingMessage = styled.span`
  color: ${(props) => props.theme.colors.black1};
`;

export const SavedMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.black1};
`;

export const StyledSaveIcon = styled(SaveIcon)`
  fill: ${(props) => props.theme.colors.text};
  width: 30px;
  margin-right: 5px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledSavingIcon = styled(SavingIcon)`
  fill: ${(props) => props.theme.colors.text};
  width: 18px;
  margin-right: 5px;
  animation: ${rotate} 1s linear infinite;
`;

import { useNavigate, useLocation } from 'react-router-dom';
import { PageContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { Dashboard } from 'components/TabMenu/Tabs/RunPayrollMenu/Dashboard/Dashboard';
import { Drafts } from 'components/TabMenu/Tabs/RunPayrollMenu/Drafts/Drafts';
import { Submitted } from 'components/TabMenu/Tabs/RunPayrollMenu/Submitted/Submitted';
import { TabMenu } from 'components/TabMenu/tabMenu';

export const Payroll = () => {
  const MENU_ITEMS = {
    Dashboard: <Dashboard />,
    Drafts: <Drafts />,
    Submitted: <Submitted />,
  };

  const navigate = useNavigate();
  const location = useLocation();

  const getActiveTabFromLocation = () => {
    const path = location.pathname.split('/').pop();
    return path ? path.charAt(0).toUpperCase() + path.slice(1) : 'Dashboard';
  };

  const handleTabChange = (tab: string) => {
    navigate(`/payroll/${tab.toLowerCase()}`);
  };

  return (
    <PageContainer>
      <SectionHeader title="Payroll" />
      <TabMenu
        menuItems={MENU_ITEMS}
        defaultActiveItem={getActiveTabFromLocation()}
        onTabChange={handleTabChange}
      />
    </PageContainer>
  );
};

import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { AddEmployeeNotification } from 'components/DashboardNotifications/AddEmployeeNotification/addEmployeeNotification';
import { NavCardList } from 'components/NavCardsList/NavCardList';
import { adminNavData, employerNavData } from '@src/constants/navData';
import { selectCurrentCompany, getCurrentCompany } from 'features/company/slice';
import { Roles } from 'features/user/types';
import { Company } from 'features/company/types';
import { ActionItemsNotification } from 'components/DashboardNotifications/ActionItemsNotification/ActionItemsNotification';
import { WelcomeNotification } from 'components/DashboardNotifications/WelcomeNotification/WelcomeNotification';

export const CompanyDashboard = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const company: Company | null = useAppSelector(selectCurrentCompany);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser?.role.name !== Roles.Employee) {
      dispatch(getCurrentCompany());
    }
  }, [dispatch, currentUser]);

  const selectedNavData =
    currentUser?.role.name === Roles.Employer ? employerNavData : adminNavData;

  return (
    <PageContainer>
      {!company?.approvedForPaymentProcessing && (
        <ContentContainer noHeight={true}>
          <WelcomeNotification />
        </ContentContainer>
      )}

      {company && company.incomingEmployeesCount > 0 ? (
        <ContentContainer noHeight={true}>
          <ActionItemsNotification count={company.incomingEmployeesCount} />
        </ContentContainer>
      ) : (
        <>
          <ContentContainer noHeight={true}>
            <AddEmployeeNotification />
          </ContentContainer>
        </>
      )}
      <ContentContainer noHeight={true}>
        <NavCardList navData={selectedNavData} />
      </ContentContainer>
    </PageContainer>
  );
};

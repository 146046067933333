import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { PageContainer, ContentContainer } from 'components/SharedComponents/Containers.styles';
import { SectionHeader } from 'components/SectionHeader/sectionHeader';
import { StepsComponent, StepDetail } from 'components/Steps/StepsComponent';
import { fetchProviderPayroll, PayrollDetails, PayrollStatus } from 'lib/fetchProviderPayroll';
import { fetchPayrollItemsList, PayrollItem, PageInfo } from 'lib/fetchPayrollItemsList';
import { runTimecardsImport } from 'lib/runTimecardsImport';
import { createPayroll, PayrollTypes, CreatePayrollInput } from 'lib/createPayroll';
import { toast } from 'react-toastify';
import { PayrollItemsTable } from 'components/Table/RunPayrollTables/PayrollITemsTable';
import { AdditionalEarningsTable } from 'components/Table/RunPayrollTables/AdditionalEarningsTable';
import { LoadingSync } from 'components/LoadingSync/LoadingSync';
import { Spinner } from 'components/Spinner/spinner';
import { ReviewStep } from 'components/Table/RunPayrollTables/ReviewStep/ReviewStep';
import { Pagination } from 'components/Pagination/pagination';
import { Payday } from 'lib/fetchCompanyNextPayday';
import {
  PaginationContainer,
  BreadcrumbContainer,
  DashboardLink,
  ActiveTab,
} from './runPayroll.styles';

export const RunPayroll = () => {
  const { stepLabel } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [payroll, setPayroll] = useState<PayrollDetails | null>(null);
  const [payrollItems, setPayrollItems] = useState<PayrollItem[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [periodDates, setPeriodDates] = useState({ start: '', end: '' });
  const [payrollProviderId, setPayrollProviderId] = useState<string | null>(null);
  const [payrollStatus, setPayrollStatus] = useState<PayrollStatus | null>(null);
  const handleSubmitRef = useRef<() => void>(() => {});
  const CUSTOM_PAGE_SIZE = 20;

  useEffect(() => {
    async function processPayroll() {
      try {
        setIsLoading(true);

        const state = location.state as {
          PayrollProviderId?: string;
          payday?: Payday;
          startDate?: string;
          endDate?: string;
          type?: PayrollTypes;
          employeeIds?: string[];
        };

        if (state && state.PayrollProviderId) {
          setPayrollProviderId(state.PayrollProviderId);

          const payrollData = await fetchProviderPayroll(state.PayrollProviderId);
          if (payrollData) {
            setPayroll(payrollData);
            setPeriodDates({ start: payrollData.periodStart, end: payrollData.periodEnd });
            setPayrollStatus(payrollData.status);

            const itemsData = await fetchPayrollItemsList(
              state.PayrollProviderId,
              CUSTOM_PAGE_SIZE
            );
            if (itemsData) {
              setPayrollItems(itemsData.items);
              setPageInfo(itemsData.pageInfo);
            } else {
              toast.error('Failed to fetch payroll items');
            }
          } else {
            toast.error('Failed to fetch payroll data');
          }
        } else {
          setIsCreating(true);
          const { payday, type, employeeIds } = state;
          if (!payday) {
            toast.error('Failed to fetch next payday details');
            setIsLoading(false);
            return;
          }

          const { periodStart, periodEnd, payFrequency } = payday;

          const completed = await runTimecardsImport({
            startDate: periodStart,
            endDate: periodEnd,
            employeeIds: employeeIds || [],
          });

          if (!completed) {
            toast.error('Timecards sync failed to complete.');
            setIsLoading(false);
            return;
          }

          toast.success('Timecards sync completed successfully!');

          const payrollInput: CreatePayrollInput = {
            startDate: periodStart,
            endDate: periodEnd,
            payday: payday.payday,
            type: type || PayrollTypes.REGULAR,
            employeeIds: employeeIds || [],
          };

          if (type !== PayrollTypes.OFF_CYCLE && payFrequency) {
            payrollInput.payFrequency = payFrequency;
          }

          const payrollCreated = await createPayroll(payrollInput);
          if (!payrollCreated) {
            toast.error('Failed to create payroll');
            setIsLoading(false);
            return;
          }

          const payrollData = await fetchProviderPayroll(payrollCreated.providerIdentifier);
          if (payrollData) {
            setPayroll(payrollData);
            setPeriodDates({ start: periodStart, end: periodEnd });
            setPayrollProviderId(payrollCreated.providerIdentifier);
            setPayrollStatus(payrollData.status);

            const itemsData = await fetchPayrollItemsList(
              payrollCreated.providerIdentifier,
              CUSTOM_PAGE_SIZE
            );
            if (itemsData) {
              setPayrollItems(itemsData.items);
              setPageInfo(itemsData.pageInfo);
            } else {
              toast.error('Failed to fetch payroll items');
            }
          } else {
            toast.error('Failed to fetch payroll data');
          }
        }
      } catch (error) {
        toast.error('An error occurred during the payroll process');
      } finally {
        setIsLoading(false);
        setIsCreating(false);
      }
    }

    processPayroll();
  }, [stepLabel, location.state]);

  const refreshPayrollData = async () => {
    if (payrollProviderId) {
      const payrollData = await fetchProviderPayroll(payrollProviderId);
      if (payrollData) {
        setPayroll(payrollData);
        setPayrollStatus(payrollData.status);

        const itemsData = await fetchPayrollItemsList(payrollProviderId, CUSTOM_PAGE_SIZE);
        if (itemsData) {
          setPayrollItems(itemsData.items);
          setPageInfo(itemsData.pageInfo);
        } else {
          toast.error('Failed to fetch payroll items');
        }
      } else {
        toast.error('Failed to fetch payroll data');
      }
    }
  };

  const handleNextPage = async () => {
    if (payrollProviderId && pageInfo?.hasNextPage) {
      const itemsData = await fetchPayrollItemsList(
        payrollProviderId,
        CUSTOM_PAGE_SIZE,
        pageInfo.endCursor
      );
      if (itemsData) {
        setPayrollItems(itemsData.items);
        setPageInfo(itemsData.pageInfo);
      }
    } else {
      toast.error('Payroll provider ID is missing');
    }
  };

  const handlePrevPage = async () => {
    if (payrollProviderId && pageInfo?.hasPreviousPage) {
      const itemsData = await fetchPayrollItemsList(
        payrollProviderId,
        CUSTOM_PAGE_SIZE,
        undefined,
        pageInfo.startCursor
      );
      if (itemsData) {
        setPayrollItems(itemsData.items);
        setPageInfo(itemsData.pageInfo);
      }
    } else {
      toast.error('Payroll provider ID is missing');
    }
  };

  const isPaginated =
    payrollItems.length > CUSTOM_PAGE_SIZE || pageInfo?.hasNextPage || pageInfo?.hasPreviousPage;

  const renderPagination = () => {
    return payrollItems.length > CUSTOM_PAGE_SIZE ||
      pageInfo?.hasNextPage ||
      pageInfo?.hasPreviousPage ? (
      <PaginationContainer>
        <Pagination
          onPrev={handlePrevPage}
          onNext={handleNextPage}
          disablePrev={!pageInfo?.hasPreviousPage}
          disableNext={!pageInfo?.hasNextPage}
        />
      </PaginationContainer>
    ) : null;
  };

  const StepContentOne = () => {
    return (
      <div>
        {renderPagination()}
        {payrollItems.length > 0 ? (
          <PayrollItemsTable
            items={payrollItems}
            periodStart={periodDates.start}
            periodEnd={periodDates.end}
            refreshPayrollData={refreshPayrollData}
            status={payrollStatus}
            isPaginated={isPaginated}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const StepContentTwo = () => {
    return (
      <div>
        {renderPagination()}
        {payrollItems.length > 0 ? (
          <AdditionalEarningsTable
            items={payrollItems}
            periodStart={periodDates.start}
            periodEnd={periodDates.end}
            refreshPayrollData={refreshPayrollData}
            status={payrollStatus}
            isPaginated={isPaginated}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const StepContentThree = () => {
    return (
      <div>
        {payrollProviderId ? (
          <ReviewStep
            payrollProviderId={payrollProviderId}
            onSubmit={(fn) => (handleSubmitRef.current = fn)}
            status={payrollStatus}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const steps: StepDetail[] = [
    { label: 'Hours', content: <StepContentOne /> },
    { label: 'Additional Earnings', content: <StepContentTwo /> },
    { label: 'Review', content: <StepContentThree /> },
  ];

  const currentStepIndex = steps.findIndex(
    (step) => step.label.toLowerCase().replace(/\s+/g, '-') === stepLabel
  );

  const handleStepChange = (stepIndex: number) => {
    navigate(`/payroll/run-payroll/${steps[stepIndex].label.toLowerCase().replace(/\s+/g, '-')}`, {
      state: {
        PayrollProviderId: payrollProviderId,
        nextPayday: location.state.nextPayday,
        type: location.state.type,
        employeeIds: location.state.employeeIds,
      },
    });
  };

  const handleSubmit = () => {
    handleSubmitRef.current();
  };

  return (
    <PageContainer>
      <SectionHeader title="Run Payroll" />
      <BreadcrumbContainer>
        <DashboardLink onClick={() => navigate('/payroll/dashboard')}>Dashboard</DashboardLink>
        <ActiveTab>Run Payroll</ActiveTab>
      </BreadcrumbContainer>
      <ContentContainer>
        {isLoading ? (
          isCreating ? (
            <LoadingSync message="Just a moment while we sync staff hours." />
          ) : (
            <Spinner />
          )
        ) : (
          <StepsComponent
            initialActiveStep={currentStepIndex >= 0 ? currentStepIndex : 0}
            steps={steps}
            onStepChange={handleStepChange}
            onSubmit={handleSubmit}
            payrollStatus={payrollStatus}
          />
        )}
      </ContentContainer>
    </PageContainer>
  );
};

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListingContainer, ListingHeader } from './employeeListing.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { EmployeesTable } from 'components/Table/EmployeesListTable/EmployeesListTable';
import { LoadingSync } from 'components/LoadingSync/LoadingSync';
import { runStaffImport } from 'lib/runStaffImport';
import { fetchIncomingEmployees, EmployeeNode } from 'lib/fetchIncomingEmployees';
import { Employee } from 'lib/fetchProviderEmployeesList';
import { toast } from 'react-toastify';
import { IncomingEmployeesModal } from 'components/Table/IncomingEmployeesTable/IncomingEmployeesModal';
import { Spinner } from 'components/Spinner/spinner';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';

export const EmployeeListing = () => {
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importCompleted, setImportCompleted] = useState(false);
  const [incomingEmployees, setIncomingEmployees] = useState<EmployeeNode[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const location = useLocation();
  const navigate = useNavigate();

  const handleImport = async () => {
    setImportLoading(true);
    try {
      const completed = await runStaffImport();
      if (completed) {
        const { employees: newEmployees } = await fetchIncomingEmployees(
          DEFAULT_PAGE_SIZE,
          undefined,
          searchName,
          'last_name',
          sortDirection
        );
        if (newEmployees.length > 0) {
          setIncomingEmployees(newEmployees);
          setIsModalOpen(true);
        }
        setImportCompleted(true);
      }
    } catch (error) {
      toast.error('Staff import failed.');
    } finally {
      setImportLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRefreshTrigger((prev) => !prev);
  };

  const handleSearchChange = async (name: string) => {
    setSearchName(name);
    setLoading(true);
    try {
      const { employees: newEmployees } = await fetchIncomingEmployees(
        DEFAULT_PAGE_SIZE,
        undefined,
        name,
        'last_name',
        sortDirection
      );
      setIncomingEmployees(newEmployees);
    } catch (error) {
      toast.error('Failed to search employees.');
    } finally {
      setLoading(false);
    }
  };

  const handleSortChange = async (newSortDirection: 'asc' | 'desc') => {
    setSortDirection(newSortDirection);
    setLoading(true);
    try {
      const { employees: newEmployees } = await fetchIncomingEmployees(
        DEFAULT_PAGE_SIZE,
        undefined,
        searchName,
        'last_name',
        newSortDirection
      );
      setIncomingEmployees(newEmployees);
    } catch (error) {
      toast.error('Failed to sort employees.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.triggerImport) {
      handleImport();
    }
  }, [location.state]);

  return (
    <ListingContainer>
      <ListingHeader>
        <ActionButton
          onClick={() => navigate('/people/add')}
          size="medium"
          title="Add Employee Manually"
          variant="outline"
        />
        {incomingEmployees.length > 0 ? (
          <ActionButton
            onClick={handleOpenModal}
            size="medium"
            title="Update Missing Information"
          />
        ) : (
          <ActionButton onClick={handleImport} size="medium" title="Import Employees" />
        )}
      </ListingHeader>
      <ContentContainer>
        {importLoading ? (
          <LoadingSync message="We're currently syncing your employee information with Payroll. This process typically takes a few minutes." />
        ) : loading ? (
          <Spinner />
        ) : (
          <EmployeesTable setEmployees={setEmployees} refreshTrigger={refreshTrigger} />
        )}
      </ContentContainer>
      <IncomingEmployeesModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        incomingEmployees={incomingEmployees}
        onSearchChange={handleSearchChange}
        onSortChange={handleSortChange}
        sortDirection={sortDirection}
        onEmployeeDeleted={(updatedEmployees: EmployeeNode[]) =>
          setIncomingEmployees(updatedEmployees)
        }
      />
    </ListingContainer>
  );
};

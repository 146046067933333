import {
  OnboaringInfoContainer,
  OnboaringInfoHeadingContainer,
  OnboaringInfoHeading,
  InfoContainer,
  InfoInstruction,
  InfoList,
  InfoListItem,
  ButtonContainer,
} from './OnboardingInfo.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';

export const OnboardingInfo = () => {
  const navigate = useNavigate();
  return (
    <OnboaringInfoContainer>
      <OnboaringInfoHeadingContainer>
        <OnboaringInfoHeading>Before you begin Onboarding</OnboaringInfoHeading>
      </OnboaringInfoHeadingContainer>
      <InfoContainer>
        <InfoInstruction>
          To ensure a smooth setup process, please gather the following information:
        </InfoInstruction>
        <InfoList>
          <InfoListItem>Primary workplace address</InfoListItem>
          <InfoListItem>Pay schedule</InfoListItem>
          <InfoListItem>Industry (e.g. educational services)</InfoListItem>
          <InfoListItem>Company type (e.g. LLC, sole proprietorship)</InfoListItem>
          <InfoListItem>Company phone number</InfoListItem>
          <InfoListItem>Company email address</InfoListItem>
          <InfoListItem>Company banking details (account and routing number)</InfoListItem>
          <InfoListItem>Federal Employer Identification Number</InfoListItem>
          <InfoListItem>
            State tax numbers (e.g. unemployment account number, UI rate, withholding account
            number)
          </InfoListItem>
        </InfoList>
      </InfoContainer>
      <ButtonContainer>
        <ActionButton
          onClick={() => navigate('/workplace')}
          size="medium"
          title="Begin Onboarding"
          hidden={false}
        />
      </ButtonContainer>
    </OnboaringInfoContainer>
  );
};

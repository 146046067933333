import { pendoApiKey } from './config';

export const initializePendo = (user, company) => {
  if (!user || !company) return;

  const {
    user: { id: userId, email: userEmail, name: userName },
  } = user;
  const { id: companyId, name: companyName } = company;

  // Pendo initialization
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
      for (w = 0; w < v.length; w++)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === 'pageLoad' ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(pendoApiKey);

  window.pendo.initialize({
    visitor: {
      id: userId,
      email: userEmail,
      full_name: userName,
    },
    account: {
      id: companyId,
      name: companyName,
    },
  });
};

import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router-dom';
import {
  LoginPage,
  SignUpPage,
  OnboardPage,
  DashboardPage,
  AccountCreatedPage,
  OnboardingPage,
  WorkplacePage,
  CompanyOnboardingPage,
  DocumentsPage,
  ProfilePage,
  PaystubsPage,
  AddEmployeePage,
  PeoplePage,
  ReportsPage,
  PaySchedulePage,
  CompanyDetailsPage,
  EmployeeProfilePage,
  PayrollPage,
  OnboardingCompletePage,
  SelectCompanyPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SyncsPage,
  RunPayrollPage,
} from './pages';
import { Verify } from 'pages/verify';
import { ProtectedLayout, AuthLayout } from './layouts';
import { PrivateRoute } from 'lib/utils/privateRoute';
import { Roles } from 'features/user/types';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} />
        <Route path="/created" element={<AccountCreatedPage />} />
        <Route path="/onboard/:confirmToken" element={<OnboardPage />} />
        <Route path="/verify/:token" element={<Verify />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        {/* Side Nav Routes BEGIN */}
        {/* Shared Routes */}
        <Route path="/" element={<DashboardPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/select-company" element={<SelectCompanyPage />} />
        <Route path="/documents" element={<DocumentsPage />} />
        {/* Employer & Payroll Admin Routes */}
        <Route
          path="/people"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <PeoplePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/people/:id"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <EmployeeProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <ReportsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/syncs"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <SyncsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/company"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <CompanyDetailsPage />
            </PrivateRoute>
          }
        />

        {/* Payroll Admin ONLY Routes */}
        <Route path="/payroll" element={<Navigate to="/payroll/dashboard" replace />} />

        <Route
          path="/payroll/dashboard"
          element={
            <PrivateRoute roles={[Roles.PayrollAdmin]}>
              <PayrollPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payroll/drafts"
          element={
            <PrivateRoute roles={[Roles.PayrollAdmin]}>
              <PayrollPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payroll/submitted"
          element={
            <PrivateRoute roles={[Roles.PayrollAdmin]}>
              <PayrollPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/payroll/run-payroll/:stepLabel"
          element={
            <PrivateRoute roles={[Roles.PayrollAdmin]}>
              <RunPayrollPage />
            </PrivateRoute>
          }
        />

        {/* Employee Routes */}
        <Route
          path="/paystubs"
          element={
            <PrivateRoute roles={[Roles.Employee]}>
              <PaystubsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute roles={[Roles.Employee]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Employer & Payroll Admin Routes BEGIN */}
        <Route
          path="/onboarding"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <OnboardingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/workplace"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <WorkplacePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pay-schedule"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <PaySchedulePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/company-onboarding"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <CompanyOnboardingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding-complete"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <OnboardingCompletePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/people/add"
          element={
            <PrivateRoute roles={[Roles.Employer, Roles.PayrollAdmin]}>
              <AddEmployeePage />
            </PrivateRoute>
          }
        />
        {/* Employer & Payroll Admin Routes END */}
      </Route>
    </Route>
  )
);

import styled from 'styled-components';
import { GroupContainer, GroupLabel } from 'components/form/FormGroup/formGroup.styles';
import { CurrencyPrefix } from 'components/form/Input/input.styles';

export const DeductionFormContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.offWhite};
  margin-top: 8px;
  ${GroupContainer} {
    padding: 0;
  }

  ${GroupLabel} {
    font-size: 14px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    margin: 8px 0;
  }
`;

export const Form = styled.form`
  margin: 10px auto 0;
  min-width: 400px;

  ${CurrencyPrefix} {
    transform: translateY(-50%);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PostTaxDeductionFormContainer = styled.div``;

export const DeductionHeading = styled.h3`
  font-size: 16px;
  color: ${(props) => props.theme.colors.black};
  margin: 5px 0;
`;

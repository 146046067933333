import styled from 'styled-components';

export const PaginationContainer = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 2;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const DashboardLink = styled.a`
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${(props) => props.theme.colors.border2};
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
`;

export const ActiveTab = styled.span`
  color: ${(props) => props.theme.colors.black0};
  font-size: 16px;
  font-weight: bold;
`;

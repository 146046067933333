import styled from 'styled-components';
import { IconButton } from 'components/IconButton/IconButton';

export const OffCyclePayrollModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const EmployeeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin: 20px 0;
  padding: 0 20px;
`;

export const EmployeeItem = styled.div<{ isInLastRow: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border-bottom: ${({ isInLastRow }) => (isInLastRow ? 'none' : '1px solid #ccc')};
`;

export const TitleContainer = styled.div`
  width: 100%;
`;

export const ModalTitle = styled.h2`
  font-weight: normal;
  font-size: 24px;
  color: ${(props) => props.theme.colors.black};
  padding: 5px 25px;
  margin: 10px 0 0;
`;

export const Step = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  margin-left: 16px;
`;

export const Instruction = styled.p`
  color: ${(props) => props.theme.colors.black};
  padding: 5px 25px;
  margin: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px 25px;
`;

export const NoStaffMessage = styled.div`
  color: ${(props) => props.theme.colors.black};
`;

export const InitialCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 16px;
`;

export const EmployeeLabel = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: bold;
`;

export const SearchContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray0};
`;

export const SearchInput = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  margin-left: 25px;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.buttonContainer};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const PeriodContainer = styled.div`
  width: 100%;
`;

export const Period = styled.div`
  font-weight: bold;
  padding: 10px 25px;
  font-size: 16px;
`;

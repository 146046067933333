import styled from 'styled-components';

export const StepPlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0 0;
`;

export const InfoPlaceholderContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

export const InfoPlaceholderTitle = styled.div`
  font-size: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.black1};
  font-weight: 500;
  white-space: pre-line;
  line-height: 1.4;
  margin-bottom: 10px;
`;

export const InfoPlaceholderDesc = styled.div`
  text-align: center;
  max-width: 800px;
  font-size: 18px;
  line-height: 1.44;
  color: ${(props) => props.theme.colors.black1};
`;

export const InfoPlaceholderImage = styled.img`
  width: 400px;
  margin-top: 20px;
`;

export const FeaturePlaceholder = styled.div`
  font-size: 14px;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.black1};
  line-height: 1.43;
  display: inline-block;
  padding: 10px;
  flex-grow: 1;
  flex-basis: 100%;

  & + & {
    border-left: 1px solid ${(props) => props.theme.colors.border2};
  }
`;

export const FeaturePlaceholderDesc = styled.div`
  font-size: 14px;
  white-space: pre-line;
  margin-top: 40px;
`;

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { offCyclePayrollSchema } from './validator';
import { OffCyclePayrollFormData } from './types';
import {
  Form,
  OffCyclePayrollFormContainer,
  ButtonContainer,
  ModalContainer,
} from './OffCyclePayrollForm.styles';
import { CloseButton, ModalTitle, TitleContainer, Step } from './OffCyclePayrollModal.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';

const defaultFormValues = {
  startDate: '',
  endDate: '',
  payday: '',
};

type OffCyclePayrollFormProps = {
  onRequestClose: () => void;
  onSubmit: (data: OffCyclePayrollFormData) => void;
};

export const OffCyclePayrollForm = ({ onRequestClose, onSubmit }: OffCyclePayrollFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<OffCyclePayrollFormData>({
    resolver: yupResolver(offCyclePayrollSchema),
    defaultValues: defaultFormValues,
  });

  const handleFormSubmit: SubmitHandler<OffCyclePayrollFormData> = (data) => {
    const formattedData = {
      startDate: moment(data.startDate).format('YYYY-MM-DD'),
      endDate: moment(data.endDate).format('YYYY-MM-DD'),
      payday: moment(data.payday).format('YYYY-MM-DD'),
    };
    onSubmit(formattedData);
  };

  const isButtonDisabled = (): boolean => {
    return !watch('startDate') || !watch('endDate') || !watch('payday');
  };

  return (
    <OffCyclePayrollFormContainer>
      <TitleContainer>
        <ModalTitle>
          Setup an Off-Cycle Payroll <Step>Step 1 / 2</Step>
        </ModalTitle>
      </TitleContainer>
      <ModalContainer>
        <Form>
          <FormGroup label="START DATE">
            <DateInput control={control} name="startDate" placeholder="Start Date" />
          </FormGroup>
          <FormGroup label="END DATE">
            <DateInput control={control} name="endDate" placeholder="End Date" />
          </FormGroup>
          <FormGroup label="PAYDAY">
            <DateInput
              control={control}
              name="payday"
              placeholder="Payday"
              dateRestriction="future"
            />
          </FormGroup>
        </Form>
        <ButtonContainer>
          <ActionButton
            onClick={handleSubmit(handleFormSubmit)}
            size="large"
            title="Continue"
            hidden={false}
            disabled={isButtonDisabled()}
          />
        </ButtonContainer>
      </ModalContainer>
      <CloseButton icon={<CloseIcon />} onClick={onRequestClose} />
    </OffCyclePayrollFormContainer>
  );
};

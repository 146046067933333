import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Heading = styled.h1`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.largeHeading};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black0};
`;

export const Message = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  margin: 16px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

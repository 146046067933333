import React, { useState, useEffect } from 'react';
import {
  InfoPlaceholderContainer,
  InfoPlaceholderTitle,
  InfoPlaceholderDesc,
  InfoPlaceholderImage,
} from './Placeholder.styles';
import PlaceholderImage from '../../assets/images/placeholders/tucker.png';

type InfoPlaceholderProps = {
  image?: string | false;
  title: string;
  children?: React.ReactNode;
};

const InfoPlaceholder = ({ image, title, children }: InfoPlaceholderProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (image) {
      import(`../../assets/images/placeholders/${image}.png`)
        .then((module) => setImageSrc(module.default))
        .catch(() => setImageSrc(PlaceholderImage));
    } else if (image === false) {
      setImageSrc(null);
    } else {
      setImageSrc(PlaceholderImage);
    }
  }, [image]);

  return (
    <InfoPlaceholderContainer>
      <InfoPlaceholderTitle>{title}</InfoPlaceholderTitle>
      <InfoPlaceholderDesc>{children}</InfoPlaceholderDesc>
      <div>
        {imageSrc && (
          <InfoPlaceholderImage
            className="info-placeholder__img"
            src={imageSrc}
            alt={image || 'default-placeholder'}
          />
        )}
      </div>
    </InfoPlaceholderContainer>
  );
};

export default InfoPlaceholder;

import React, { useState, useEffect } from 'react';
import { Input, InputWrapper } from './TableInput.styles';

type TableInputProps = {
  value: number | string;
  className?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  format?: 'currency' | 'hours';
};

export const TableInput = ({
  value,
  className = '',
  onChange,
  onBlur,
  placeholder = '',
  disabled = false,
  format = 'hours',
}: TableInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (format === 'currency') {
      const numericInput = newValue.replace(/[^0-9.]/g, '');
      const decimalParts = numericInput.split('.');

      if (decimalParts.length > 1 && decimalParts[1].length > 2) return;

      setInputValue(numericInput);
      onChange(numericInput);
    } else {
      const validHours = newValue.replace(/[^0-9.]/g, '');
      setInputValue(validHours);
      onChange(validHours);
    }
  };

  const handleInputBlur = () => {
    if (format === 'currency') {
      const numericValue = parseFloat(inputValue.replace(/[^0-9.-]/g, '')) || 0;
      const formattedValue = `$${numericValue.toFixed(2)}`;
      setInputValue(formattedValue);
      onChange(numericValue.toFixed(2));
    } else {
      const numericValue = parseFloat(inputValue) || 0;
      setInputValue(numericValue.toString());
      onChange(numericValue.toString());
    }

    onBlur?.();
  };

  return (
    <InputWrapper>
      <Input
        className={className}
        type="text"
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

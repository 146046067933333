import styled from 'styled-components';
import { RadioInputContainer, Label } from 'components/form/RadioInput/RadioInput.styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
`;

export const PayrollContainer = styled.div`
  width: 100%;
  max-width: 1300px;
`;

export const SectionHeading = styled.h3`
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 10px;
`;

export const RunPayrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  margin: 16px 0;
`;

export const RadioContainer = styled.div`
  ${RadioInputContainer} {
    display: flex;
  }

  ${Label} {
    margin-right: 40px;
    text-transform: uppercase;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 100%;
  max-width: 1300px;
  margin: 0 16px;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  font-size: 16px;
`;

import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export type Payday = {
  approvalDeadline: string;
  impactedByWeekendOrHoliday: boolean;
  payday: string;
  periodEnd: string;
  periodStart: string;
  payFrequency?: 'weekly' | 'biweekly' | 'semimonthly' | 'monthly' | 'quarterly' | 'annually';
};

type CompanyNextPaydayResponse = {
  data: {
    getPaySchedule: {
      nextPayday: Payday;
      payFrequency: 'weekly' | 'biweekly' | 'semimonthly' | 'monthly' | 'quarterly' | 'annually';
    };
  };
};

export const fetchCompanyNextPayday = (): Promise<
  (Payday & { payFrequency: Payday['payFrequency'] }) | null
> => {
  const query = `
    query {
      getPaySchedule {
        nextPayday {
          approvalDeadline
          impactedByWeekendOrHoliday
          payday
          periodEnd
          periodStart
        }
        payFrequency
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<CompanyNextPaydayResponse>>('graphql', {
      query,
    })
    .then((response: AxiosResponse<CompanyNextPaydayResponse>) => {
      const { nextPayday, payFrequency } = response.data.data.getPaySchedule;
      return { ...nextPayday, payFrequency };
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      return null;
    });
};

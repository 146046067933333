import styled from 'styled-components';
import { ReactComponent as SaveIcon } from '@src/assets/icons/save.svg';

export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const SaveIconWrapper = styled.div`
  position: absolute;
  right: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 80px;
  padding: 8px 5px;
  border: 1px solid ${(props) => props.theme.colors.border2};
  border-radius: 8px;

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const StyledSaveIcon = styled(SaveIcon)`
  fill: ${(props) => props.theme.colors.text};
  width: 30px;
`;

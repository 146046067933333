// Admin Navigation
import { IconType } from '../assets/icons/index';

export type NavItem = {
  title: string;
  url: string;
  icon: IconType;
  id: string;
  description?: string;
  customText?: string;
  customLinkText?: string;
  isExternal?: boolean;
};

export const adminNavData: NavItem[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'dashboard',
    id: 'dashboard',
  },
  {
    title: 'People',
    url: '/people',
    icon: 'people',
    id: 'people',
    description: 'Add or edit your employees to keep their information up-to-date.',
    customText: 'View Employees',
    customLinkText: 'Manage Employees',
  },
  {
    title: 'Run Payroll',
    url: '/payroll',
    icon: 'payroll',
    id: 'payroll',
    description: 'View and run your latest payroll to ensure your employees get paid on time.',
    customText: 'Latest Payroll',
    customLinkText: 'Run Payroll',
  },
  {
    title: 'Reports',
    url: '/reports',
    icon: 'reports',
    id: 'reports',
    description: 'Track your payroll trends over time with our historical reporting.',
    customText: 'View Reports',
    customLinkText: 'Go to Reports',
  },
  {
    title: 'Documents',
    url: '/documents',
    icon: 'documents',
    id: 'documents',
  },
  {
    title: 'Syncs',
    url: '/syncs',
    icon: 'syncs',
    id: 'syncs',
  },
  {
    title: 'Company Details',
    url: '/company',
    icon: 'company',
    id: 'company',
    description: 'Add or update any information related to your company.',
    customText: 'Company Info',
    customLinkText: 'View Company Info',
  },
];

export const employeeNavData: NavItem[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'dashboard',
    id: 'dashboard',
  },
  {
    title: 'Paystubs',
    url: '/paystubs',
    icon: 'reports',
    id: 'paystubs',
    description: 'View your latest paystubs.',
    customText: 'Latest Paystubs',
  },
  {
    title: 'Documents',
    url: '/documents',
    icon: 'documents',
    id: 'documents',
    description: 'Get an outlook of your personal documents.',
    customText: 'View Documents',
  },
  {
    title: 'Basic Information',
    url: '/profile',
    icon: 'company',
    id: 'profile',
    description: 'Add or update any information related to your personal information.',
  },
];

export const employerNavData: NavItem[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'dashboard',
    id: 'dashboard',
  },
  {
    title: 'People',
    url: '/people',
    icon: 'people',
    id: 'people',
    description: 'Add or edit your employees to keep their information up-to-date.',
    customText: 'View my Employees',
    customLinkText: 'Manage Employees',
  },
  {
    title: 'Reports',
    url: '/reports',
    icon: 'reports',
    id: 'reports',
    description: 'Get an outlook of your preview payrolls with your historical reports.',
    customText: 'View Reports',
    customLinkText: 'Go to Reports',
  },
  {
    title: 'Documents',
    url: '/documents',
    icon: 'documents',
    id: 'documents',
  },
  {
    title: 'Syncs',
    url: '/syncs',
    icon: 'syncs',
    id: 'syncs',
  },
  {
    title: 'Company Details',
    url: '/company',
    icon: 'company',
    id: 'company',
    description: 'Add or update any information related to your company.',
    customText: 'Company Info',
    customLinkText: 'View Company Info',
  },
];

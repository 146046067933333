import styled from 'styled-components';

type ContentContainerProps = {
  contentAlign?: 'center' | 'left';
  noHeight?: boolean;
  onboarding?: boolean;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.border2};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.onboarding
      ? 'margin: 8px; min-height: calc(100vh - 100px);'
      : 'width: 100%; margin: 8px 0 16px;'}
  ${(props) => !props.noHeight && !props.onboarding && 'min-height: 416px;'}
  padding: 8px 16px;

  ${(props) =>
    props.contentAlign === 'center' &&
    `
    display: flex;
    justify-content: center;
  `}
`;

export const PageContainer = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0 16px;
`;

type SectionProps = {
  halfWidth?: boolean;
};

export const Section = styled.div<SectionProps>`
  border: 1px solid ${(props) => props.theme.colors.border1};
  border-radius: 16px;
  padding: 20px;
  margin: 12px 8px;
  background-color: #fff;
  ${(props) => props.halfWidth && 'width: 49%;'}
`;
